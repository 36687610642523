import { render, staticRenderFns } from "./KnAuthorizationGradesPerStudent.vue?vue&type=template&id=2afd58b4&scoped=true"
import script from "./KnAuthorizationGradesPerStudent.js?vue&type=script&lang=js&external"
export * from "./KnAuthorizationGradesPerStudent.js?vue&type=script&lang=js&external"
import style0 from "./KnAuthorizationGradesPerStudent.vue?vue&type=style&index=0&id=2afd58b4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2afd58b4",
  null
  
)

export default component.exports