export const setLocalData = (dataBk = { data: null, props: null }) => {
  localStorage.setItem('kpBkData', JSON.stringify(dataBk));
};

export const getLocalData = () => {
  const localStorageData = localStorage.getItem('kpBkData');
  if (localStorageData) {
    return JSON.parse(localStorageData);
  } else {
    return null;
  }
};

export const removeLocalData = () => {
  localStorage.removeItem('kpBkData');
};
