import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import {
  fetchEvaluationPeriods,
  // fetchSubjectStudent,
  fetchSubjectStudents,
  getNameOfSubjectHolder,
} from '../../helpers/syllabusOptions';
import KnAuthorizationGradeItem from '../KnAuthorizationGradeItem/KnAuthorizationGradeItem.vue';
import KnAuthorizationGradeDetail from '../KAuthorizationGradeDetail/KnAuthorizationGradeDetail.vue';
import KnAuthorizationMiniGradeItem from '../KnAuthorizationMiniGradeItem/KnAuthorizationMiniGradeItem.vue';

import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import {
  fetchGrades,
  fetchGradeDetails,
  GradeActions,
  updateGradeStatus,
} from '../../helpers/gradeOptions';
import { fetchQualificationStatus } from '../../../configuration/helpers/KnGroupsOptions';
import { mapState } from 'vuex';
import { getFullName } from '../../../shared/helpers/dataUtils';
import {
  getLocalData,
  removeLocalData,
  setLocalData,
} from '../../../income/helpers/incomeStorage';

export default {
  name: 'KnAuthorizationGradesPerStudent',
  components: {
    KnAuthorizationGradeDetail,
    KnAuthorizationGradeItem,
    KnAuthorizationMiniGradeItem,
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormTitle,
    KnLocalAlert,
    KnPagination,
  },
  mixins: [paginationMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bkEntity: null,
      bkReadonly: false,
      valueDeterminate: 100,
      routerName: 'Autorizar Calificaciones',
      valid: true,
      grade: null,
      gradeDetails: [],
      gradeData: null,
      subjectStudents: [],
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
      loadingDetails: false,
      showDetail: false,
      gradeStatus: null,
      selectedEvaluationPeriod: null,
      periods: [],
      showEvaluationPeriod: true,
      students: [],
      subjectsNamesHeader: [],
    };
  },
  computed: {
    ...mapState(['institutionId']),
    isNewMode() {
      return this.bkEntity === null;
    },

    groupName() {
      return !this.isNewMode ? this.bkEntity.nombre_grupo : '';
    },
    periodFilter() {
      return !this.isNewMode
        ? this.bkEntity && this.bkEntity.evaluationPeriodFilter
          ? this.bkEntity.evaluationPeriodFilter
          : null
        : null;
    },
    schoolYearFilter() {
      return !this.isNewMode
        ? this.bkEntity.ciclo_escolar
          ? this.bkEntity.ciclo_escolar.dato
          : this.bkEntity.schoolYearFilter
          ? this.bkEntity.schoolYearFilter.dato
          : 'No especificado'
        : 'No especificado';
    },
    syllabusFilter() {
      return !this.isNewMode
        ? this.bkEntity.plan_estudios
          ? this.bkEntity.plan_estudios.nombre
          : this.bkEntity.syllabusFilter
          ? this.bkEntity.syllabusFilter.nombre
          : 'No especificado'
        : 'No especificado';
    },
    currentSyllabus() {
      return !this.isNewMode
        ? this.bkEntity.plan_estudios
          ? this.bkEntity.plan_estudios
          : this.bkEntity.syllabusFilter
          ? this.bkEntity.syllabusFilter
          : null
        : null;
    },
    getGroupOwnerName() {
      let groupOwner = null;
      if (!this.isNewMode && this.bkEntity.profesores.length) {
        if (this.bkEntity.profesores.length === 1) {
          groupOwner = this.bkEntity.profesores[0];
        } else {
          groupOwner = this.bkEntity.profesores.find((p) => p.titular_grupo);
        }
      }
      return groupOwner
        ? getFullName(groupOwner.datos_personales)
        : 'No especificado';
    },
    numberOfStudents() {
      return !this.isNewMode ? this.bkEntity.alumnos.length : 0;
    },
  },
  async created() {
    this.setPaginationLimit(30);
    this.loading = true;

    this.bkEntity = this.entity;
    this.bkReadonly = false;

    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.openAlert();
    const localData = getLocalData();

    if (localData) {
      Object.assign(this.$data, localData.data);
    } else {
      this.subjectsNamesHeader = await this.getSubjectsNamesHeader();
      await this.setEvaluationPeriods();
    }
    this.closeAlert();
    // console.log('Entity grupo', this.entity);
    setLocalData({
      data: this.$data,
      props: { entity: this.entity, readonly: false },
    });
  },
  updated() {
    // console.log('Hubo un cambio', this.$data);
    setLocalData({
      data: this.$data,
      props: { entity: this.entity, readonly: false },
    });
  },
  beforeRouteLeave(to, from, next) {
    // console.log('beforeLeave to: ', to, ' from: ', from);
    removeLocalData();
    next();
  },
  methods: {
    getFullName: getFullName,
    async setEvaluationPeriods() {
      if (this.showEvaluationPeriod) {
        const { ok: okEvaluationPeriod, data: dataEvaluationPeriod } =
          await fetchEvaluationPeriods({
            institutionId: this.institutionId,
            systemStatus: true,
            startDate:
              !this.isNewMode && this.bkEntity.ciclo_escolar
                ? this.bkEntity.ciclo_escolar.fecha_inicio
                : null,
            endDate:
              !this.isNewMode && this.bkEntity.ciclo_escolar
                ? this.bkEntity.ciclo_escolar.fecha_fin
                : null,
            limit: 100,
          });

        this.periods = okEvaluationPeriod ? dataEvaluationPeriod : [];
      }
    },
    async getEvaluations() {
      if (!this.isNewMode) {
        this.loading = true;
        this.students = this.bkEntity.alumnos;
        for (const student of this.students) {
          for (const subject of student.materias) {
            const { ok, data, message } = await fetchSubjectStudents({
              studentId: student.id,
              subjectId: subject.id,
              systemStatus: true,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });
            if (ok) {
              for (const subjectstudent of data) {
                // console.log('subjectstudent', subjectstudent);

                const { ok: okGrade, data: dataGrade } = await fetchGrades({
                  institutionId: this.institutionId,
                  materiaAlumno: subjectstudent.id,
                  periodo: this.selectedEvaluationPeriod
                    ? this.selectedEvaluationPeriod.id
                    : null,
                  systemStatus: true,
                  limit: 100,
                });
                if (okGrade) {
                  subject.grade = dataGrade.length ? dataGrade[0] : null;
                }
              }
            } else {
              console.log('No se pudieron obtener materiasAlumnos', message);
            }
          }
        }
        // console.log('Alumnos con calificacion?', this.students);

        this.loading = false;
      }
    },
    async getSubjectsNamesHeader() {
      let subjects = [];
      if (this.currentSyllabus) {
        const allSubjects = [
          ...this.currentSyllabus.materias_curriculares,
          ...this.currentSyllabus.materias_extracurriculares,
        ];
        for (const subject of allSubjects) {
          // console.log('Materia revisar su titular', subject);
          // console.log('titular', subject.titular_materia);

          const personalData = await getNameOfSubjectHolder(
            subject.titular_materia
          );
          const item = {
            id: subject.id,
            nombre: subject.nombre,
            titular_grupo: personalData,
          };
          subjects.push(item);
        }
      }
      return subjects;
    },
    getStudentSubject(subjects, subjectId) {
      return subjects.find((subject) => subject.id === subjectId);
    },

    async getGradeDetails(gradeId) {
      const { ok, data } = await fetchGradeDetails({
        gradeId: gradeId,
        systemStatus: true,
        limit: 50,
      });
      // console.log('');
      if (ok) {
        return data;
      }
      return [];
    },
    async getGradeStatus(name) {
      const { ok, data } = await fetchQualificationStatus({
        dato: name,
        systemStatus: true,
        institutionId: this.institutionId,
      });
      if (!ok) return null;
      return data.length ? data[0] : null;
    },
    async runGradeAction(gradeData) {
      // console.log('informacion de calificacion', gradeData);
      this.alertText = 'Cambiando estatus. Por favor, espera';
      this.alertType = 'info';
      // this.loading = true;
      this.openAlert();
      // this.gradeData = gradeData;
      this.grade = gradeData;
      this.loadingDetails = true;
      let gradeStatus = null;
      switch (gradeData.action) {
        case GradeActions.PAUSE:
          gradeStatus = await this.getGradeStatus('revisión');
          break;
        case GradeActions.ACCEPT:
          gradeStatus = await this.getGradeStatus('aprob');
          break;
        case GradeActions.REJECT:
          gradeStatus = await this.getGradeStatus('rechaz');
          break;
        default:
          this.errors.push('Acción no permitida');
        // console.log('Accion no permitida');
      }
      if (gradeStatus) {
        const { ok, message } = await updateGradeStatus(
          this.grade.id,
          gradeStatus.id
        );
        if (ok) {
          // console.log(
          //   'Estatus de calificación actualizada exitosamente.',
          //   data
          // );
          await this.getEvaluations();
        } else {
          // console.log('Error al actualizar estatus de calificación. ', message);
          this.errors.push(message);
        }
      } else {
        this.errors.push('No se pudo encontrar el estatus de calificación');
      }
      if (this.errors.length) {
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = 'success';
        this.alertText = 'Estatus de calificación actualizada exitosamente';
      }
      // this.loading = false;
      this.loadingDetails = false;
    },
    async loadDetail(gradeData) {
      // console.log('informacion de calificacion', gradeData);
      this.loadingDetails = true;
      this.openDetail();
      this.grade = gradeData;
      this.gradeDetails = await this.getGradeDetails(this.grade.id);
      this.loadingDetails = false;
    },
    async toEdit(/*gradeData*/) {
      // console.log(gradeData);
    },
    openDetail() {
      this.showDetail = true;
    },
    closeDetail() {
      this.showDetail = false;
    },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    openAlert() {
      this.showAlert = true;
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
  },
};
